import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
//import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const NewsRheinlandPfalzCH = () => {
  return (
    <Layout pageTitle="RheinlandPfalzArtikelCH" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Artikel - Dezember 2023</p> 
                <h1>Mehr Informationseffizienz für Leistungserbringer, ihre Partner und Patienten</h1>
   
                <p>Für alle, die erfahren möchten, warum sich 4 Krankenhausträger zur 
                  „Digitalen Gesundheitsplattform in Rheinland-Pfalz“ vernetzt und dafür die Lösung von 
                  uns gewählt haben, empfehlen wir diesen Artikel aus dem aktuellen clinicum Magazin.</p>

                <div className={buttonGroup}><a className="button-outline blue" href="https://www.clinicum.ch/images/getFile?t=ausgabe_artikel&f=dokument&id=3017" target="_blank" rel="noreferrer" >Zum Artikel</a></div>


              
              
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NewsRheinlandPfalzCH;
